<template lang="pug">
div(id='top' class='is-simple-mode')
  ClientOnly
    AgentToolbar(v-if='isIntegrationEnabled("cognito") && $cognito.isLoggedIn() && $cognito.isSalesAgent()')

  HeaderLinkBar(is-simple)
  Header(id='Header' is-simple)
  slot
  Footer(id='Footer' is-simple)
</template>

<script setup lang="ts">
const { $cognito } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
</script>
